<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row>
        <b-col cols="8">
          <b-card>
            <label for="items" class="text-primary font-weight-bolder mb-1"
              >Detail</label
            >
            <b-row>
              <b-col>
                <b-form-group label="Kategori" label-for="category">
                  <b-form-select
                    id="category"
                    v-model="form.selectedCategory"
                    :options="form.optionsCategory"
                    required
                  />
                  <!-- Selected: <strong>{{ form.selectedCategory }}</strong> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Kode" label-for="code">
                  <b-form-input
                    id="code"
                    placeholder="Kode"
                    v-model="form.code"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Merk" label-for="brand">
                  <b-form-input
                    id="brand"
                    placeholder="Merk"
                    v-model="form.brand"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Nama" label-for="name">
                  <b-form-input
                    id="name"
                    placeholder="Nama"
                    v-model="form.name"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Harga" label-for="harga">
                  <b-form-input
                    id="harga"
                    placeholder="Harga"
                    v-model="form.price"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Deskripsi" label-for="description">
              <quill-editor v-model="form.description" />
            </b-form-group>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-2"
              @click="addProduct()"
            >
              Simpan
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-form-group
              :state="errors"
              label="Foto Katalog"
              label-for="Image"
              invalid-feedback="Foto Katalog Tidak Boleh Kosong"
            >
              <validation-provider
                #default="{ errors }"
                name="Photo"
                rules="required"
              >
                <image-uploader
                  id="file"
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  :debug="0"
                  :autoRotate="true"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  outputFormat="string"
                  @input="setImage"
                ></image-uploader>
                <small class="text-danger">{{
                  errors[0] || errMessageImage
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BContainer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        code: "",
        name: "",
        selectedCategory: null,
        optionsCategory: [],
        description: ``,
        brand: "",
        price : ""
      },
      errors: "",
      errMessage: "",
      hasImage: "",
      image: "",
      photo: "",
      errMessageImage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getCategories();
  },
  updated() {
    console.log(document.getElementById("file").files[0]);
  },
  methods: {
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
    getCategories() {
      this.$http.get("unit/categories/all").then((response) => {
        // console.log(response.data.data)
        this.form.optionsCategory.push({
          value: null,
          text: "Please select 1 Category",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.form.optionsCategory.push($data);
          } else {
            var $dataParent = {
              value: datas[i].id,
              text: datas[i].name,
              disabled: true,
            };
            this.form.optionsCategory.push($dataParent);

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.form.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.form.optionsCategory)
      });
    },
    addProduct() {
      this.$http
        .post("catalog/add", {
          code: this.form.code,
          name: this.form.name,
          category_id: this.form.selectedCategory,
          image: this.image,
          description: this.form.description,
          brand: this.form.brand,
          price: this.form.price
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success add Unit",
              variant: "success",
            },
          });
          location.href = "/catalogs";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
#category option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#category option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
img.img-preview {
  width: 100%;
  margin-bottom: 15px;
}
</style>